<template>
  <div class="container mt-3 mb-5">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black"
            ><i class="fas fa-home"></i
          ></router-link>
        </li>
        <li class="breadcrumb-item active" style="cursor: pointer">
          Tentang e-Bimbingan
        </li>
      </ol>
    </nav>
    <div class="p-5 bg-white rounded box">
      <h2 class="mb-4">Tentang e-Bimbingan</h2>

      <p class="mb-5">
        e-Bimbingan Haji merupakan satu sistem pengurusan pembelajaran digital yang dibangunkan bagi menyediakan platfom khusus untuk Bakal Haji (BH) tahun semasa, Pengkursus Ibadat Haji Tanah Air (PEKTA), petugas haji dan pengguna umum yang bakal menunaikan haji atau umrah. Sistem ini membantu pengguna untuk mempelajari ilmu berkaitan dengan haji dengan lebih mudah secara dalam talian.
      </p>

      <h5 class="fw-bolder">KURSUS YANG DITAWARKAN</h5>

      <p>Antara kandungan kursus yang ditawarkan dalam e-Bimbingan Haji adalah:</p>
      <ol class="pb-4">
        <li class="mb-3">
          Pengguna Umum: 
          <ul>
            <li>Kursus Asas Haji (KAH)</li>
            <li>Bahan-bahan rujukan umum</li>
          </ul>
        </li>
        <li class="mb-3">
          Bakal Haji: 
          <ul>
            <li>Kursus Asas Haji (KAH)</li>
            <li>Kursus Intensif Haji (KIH)</li>
            <li>Kursus Perdana Haji (KPH)</li>
          </ul>
        </li>
        <li class="mb-3">
          PEKTA: 
          <ul>
            <li>Kursus Tauliah Haji dan Umrah (KUTAHU)</li>
            <li>Kelas Intensif Tafaqquh Haji dan Umrah (KITHU)</li>
            <li>Muzakarah Haji Peringkat Kebangsaan (MHPK)</li>
            <li>Sijil Pembimbing Ibadat Haji (SPIH)</li>
          </ul>
        </li>
        <li class="mb-3">
          Petugas Haji: 
          <ul>
            <li>Modul Petugas Haji</li>
            <li>Kursus Asas Haji (KAH)</li>
            <li>Kursus Intensif Haji (KIH)</li>
            <li>Kursus Perdana Haji (KPH)</li>
          </ul>
        </li>
      </ol>

      <h5 class="fw-bolder">PANDUAN PENGGUNA</h5>
      <p>
        Untuk menggunakan sistem e-Bimbingan Haji ini dengan lebih tepat, pengguna dialu-alukan untuk memuat turun fail PDF di pautan yang tertera.
      </p>
      <a :href="manualPDFLinks" target="_blank">Klik untuk memuat turun fail panduan pengguna.</a>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "contactUs",
  data() {
    return {
      manualPDFLinks: null
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  async mounted(){
    console.log('currentUser : ', this.currentUser)
    if(!this.currentUser) 
      this.manualPDFLinks = 'https://tabunghaji.sgp1.digitaloceanspaces.com/MANUAL_PENGGUNA_PENGGUNA_AWAM_V02_6d2c550c5f.pdf'
    else if(this.currentUser && this.currentUser.role) {
      if(this.currentUser.role.name == 'BH')
        this.manualPDFLinks = 'https://tabunghaji.sgp1.digitaloceanspaces.com/MANUAL_PENGGUNA_BAKAL_HAJI_V2_48c187934b.pdf'
      else if(this.currentUser.role.name == 'Pekta')
        this.manualPDFLinks = 'https://tabunghaji.sgp1.digitaloceanspaces.com/MANUAL_PENGGUNA_PEKTA_PIHTAS_V2_71ff00f3d9.pdf'
    }
  }
};
</script>

<style scoped>
.box {
  position: relative;
  display: block;
  min-height: calc(100vh - 270px);
}

p, li {
  font-size: 18px !important;
  line-height: 28px;
}
</style>